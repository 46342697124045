import React from "react";
import moment from "moment";
import "moment/locale/id";
import getRoman from "../misc/getRoman";
import getZeroPad from "../misc/getZeroPad";

export default function Discharge({ patient, letter }) {
    return (
        <div>
            <h5 className="text-center">Surat Lepas Rawat</h5>
            <table className="table table-sm">
                <tbody>
                    <tr>
                        <th>No</th>
                        <td>
                            {letter.letter_no
                                ? getZeroPad(letter.letter_no, 3) +
                                  "/SLR" +
                                  (letter.category ? "/" + letter.category.charAt(0) : "") +
                                  "/RSCS/" +
                                  getRoman(moment(letter.createdAt).format("M")) +
                                  "/" +
                                  moment(letter.createdAt).format("YYYY")
                                : letter.letter_no}
                        </td>
                    </tr>
                    <tr>
                        <th>Nama</th>
                        <td>{patient.fullName}</td>
                    </tr>
                    <tr>
                        <th>Tanggal</th>
                        <td>{moment(letter.createdAt).format("dddd, DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                        <th>Diagnosa</th>
                        <td>{letter.diagnosis}</td>
                    </tr>
                    <tr>
                        <th>Tgl Pulang</th>
                        <td>{moment(letter.dischargeDate).format("dddd, DD-MM-YYYY")}</td>
                    </tr>
                    <tr className="table-primary text-center">
                        <th colSpan={2}>Terverifikasi</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
